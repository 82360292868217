@import '../../styles/mixins';

.reports-new-reports-filter {
  background-color:#EFF7FB;
  min-height: 35px;
  .ant-card-body {
    padding: 2px;
  }

  .reports-filter-form {
    .sub-title {
      padding-bottom: 3px;
      display: flex;
      align-items: center;
    }
    .sub-title-show {
      // display: flex;
      // align-items: center;
      margin-right:20px;
      vertical-align: bottom;
    }

    .ant-form-item {
      margin-bottom: 0px;

      .ant-col {
        &.ant-form-item-label {
          padding: 0 0 2px;
        }

        .ant-picker {
          width: 100%;
        }
      }
    }

    .ant-select {
      font-size: 12px;
    }

    .ant-picker-input>input {
      font-size: 12px;
    }

    .ant-btn {
      padding: 4px 10px;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border: 1px solid #1B1B1B;
      color: #606266;
      background-color: #FFFFFF;
      border-radius: 4px;
    }
    .ant-form-item-has-error .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border-color: #ff4d4f;
    }
    .ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background: #FFFFFF;
  }
    .ant-select-selection-placeholder{
      color: #606266 !important;
    }
    .ant-picker{
      border: 1px solid #1B1B1B ;
      background-color:#FFFFFF;
      border-radius: 4px;
      height: 32px;
    }
    .ant-form-item-explain-error{
      font-size: 12px;
    }
    .error-delivery-date{
      .ant-form-item-with-help .ant-form-item-explain{
        width: 140px;
      }
    }
    .ant-picker-status-error {
      border: 1px solid #ff4d4f;
      border-radius: 4px;
      box-shadow: unset;
    }
  }
  .ant-collapse > .ant-collapse-item {
    border-bottom: none;
    }
    .ant-collapse{
      background-color: #EFF7FB; 
      border: none;
    } 
    .ant-collapse-content {
      background-color: unset; 
    }
    .ant-collapse-content > .ant-collapse-content-box {
      padding-bottom: 0px;
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      padding: 4px 12px;
      font: 14px/16px MyriadPro-Bold, sans-serif;
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
      margin-right: 0px;
    }
}

.sia-filter-pc {
  // display: flex;
  // margin-left:10px;
  // width: 1210px;
  // height: 32px;
  // justify-content: space-between;
}
.sia-filter-pc-row{
  justify-content: space-between;
}
.filter-but{
  position: absolute;
  right: 6px;
}
// @media screen and (max-width: 1365px) {
  // .sia-filter-pc {
    // width: 925px !important;
  // }
// }
// @media screen and (max-width: 1407px) {
//   .sia-filter-mobile{
//     display: none;
//   }
//   .sia-filter-pc {
//     display: flex;
//     width: 1168px;
//     height: 32px;
//     justify-content: space-between;
//     margin-bottom: 20px;
//   }
//   .sia-filter-pc-row{
//     justify-content:start;
//   }
//   .sia-filter-pc-row{
//     .sub-title{
//       margin-top: -25px;
//     }
//   }
// }

@media screen and (max-width: 800px) {
  .ant-picker-dropdown,.ant-select-dropdown{
    z-index: 999999;
  }
  .reports-new-reports-filter {
    background-color: #EFF7FB;
    height: 48px;
    .sia-filter-pc{
      display: none;
    }
    .sia-filter-mobile{
      display: block;
      position: fixed;
      width: 35.5rem;
      background-color: #fff;
      border-radius: 3px;
      top:6.6rem;
      left:1.1rem;
      z-index: 99999;
      .sia-filter-top{
        width: 100%;
        height: 3.6rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        h4{
          font-size: 1.6rem;
          color: #1B1B1B;
          font-weight: 600;
          margin-left:1.5rem;
          margin-top:2rem;
        }
        .filter-but-right-mobile{
          color: #008CD2;
        }
      }
      .sia-filter-content{
        padding:2rem 5rem;
        .ant-select-selector,.ant-picker{
          width: 24rem !important;
        }
        .ant-select-selector{
          margin-bottom: 15px;
        }
      }
      .sia-filter-footer{
        display: flex;
        margin-bottom: 16px;
        justify-content: end;
        padding-right:5.8rem;
        .sia-reset-but{
          width: 64px;
          height: 30px;
          background: #FFFFFF;
          border: 1px solid #008CD2;
          border-radius: 30px;
          color: #008CD2;
        }
        .sia-search-but{
          width: 64px;
          height: 30px;
          background: #008CD2;
          border: 1px solid #008CD2;
          border-radius: 30px;
          color: #fff;
        }
      }
    }
    .ant-card-body {
      padding: 12px;
    }
  
    .reports-filter-form {
      .sub-title {
        margin-top: 4px;
      }
  
      .sub-title-show {
        display: flex;
        align-items: center;
        margin-right: 30px;
      }
  
      .ant-form-item {
        margin-bottom: 0px;
  
        .ant-col {
          &.ant-form-item-label {
            padding: 0 0 2px;
          }
  
          .ant-picker {
            width: 100%;
          }
        }
      }
  
      .ant-select {
        font-size: 12px;
      }
  
      .ant-picker-input>input {
        font-size: 12px;
      }
  
      .ant-btn {
        padding: 4px 10px;
      }
  
      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border: 1px solid #dcdfe6;
        color: #606266;
        background-color: #FFFFFF;
        border-radius: 4px;
      }
      .ant-select-selection-placeholder{
        color: #606266 !important;
      }
      .ant-picker{
        border: 1px solid #dcdfe6 ;
        background-color:#FFFFFF;
        border-radius: 4px;
        height: 32px;
      }
      .filter-but-right-mobile{
        position: absolute;
        right: 13px;
        margin-top:5px;
      }
      .ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background: #FFFFFF;
      }
      
      .ant-form-item-explain-error {
        font-size: 12px;
      }
      .ant-picker-status-error {
        border: 1px solid #ff4d4f;
        border-radius: 4px;
        box-shadow: unset;
      }
    }
  }
}
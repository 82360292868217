@import './mixins';


// Here you put all global css rules.
:root {
  --green-color: #56B146; 
  --green2-color: #1da57a;
  --blue-color: #008CD2; 
  --blue-primary-color: #0070D2;
  --orange-color: #FF8400;
  --red-color: #D0021B;
  --blue-heading-color: #284E6F;
  --checkbox-green: #6DAF55;
}

/* MyriadPro-Regular */
@font-face {
  font-family: 'MyriadPro';
  font-style: normal;
  src: url('../fonts/MyriadPro-Regular.woff') format('woff'), /* chrome、firefox */
       url('../fonts/MyriadPro-Regular.otf') format('opentype');
}
/* MyriadPro-Bold */
@font-face {
  font-family: 'MyriadPro-Bold';
  font-style: normal;
  src: url('../fonts/MyriadPro-Bold.woff') format('woff'), /* chrome、firefox */
       url('../fonts/MyriadPro-Bold.otf') format('opentype');
}

/* MyriadPro-Semibold */
@font-face {
  font-family: 'MyriadPro-Semibold';
  font-style: normal;
  src: url('../fonts/MyriadPro-Semibold.otf') format('opentype');
}

body {
  margin: 0;
  padding: 0;
  font-family: MyriadPro, sans-serif;
  background: transparent url('../images/loading.gif') 50% 50% no-repeat fixed padding-box;
  // background-size: cover;
}

// body {
//   margin: 0;
//   padding: 0;
//   font-family: MyriadPro, sans-serif;
// }
// #root {
//   background: transparent url('../images/loading.gif') 50% 50% no-repeat fixed padding-box;
//   transform: scale(0.5);
//   width: 100%;
//   height: 100%;
//   // background-size: cover;
// }
.font-size-12 {
  font: 400 12px/14px MyriadPro, sans-serif;
}
.font-size-14 {
  font: 500 14px/16px MyriadPro, sans-serif;
}
.font-size-14-bold {
  font: 14px/16px MyriadPro-Bold, sans-serif;
}
.font-size-16 {
  font: 600 16px/17px MyriadPro, sans-serif;
}
.font-size-16-bold {
  font: 600 16px/17px MyriadPro-Bold, sans-serif;
}
.font-size-18 {
  font: 500 18px/20px MyriadPro, sans-serif;
}
.font-size-24 {
  font: 24px/26px MyriadPro, sans-serif;
}
.font-size-24-bold {
  font: 24px/26px MyriadPro-Bold, sans-serif;
}
.font-italic {
  font-style: italic;
}

.cursor-pointer {
  cursor: pointer;
}

.flex {
  display: flex;
}
.justify-center {
  justify-content: center;
}
.justify-end {
  justify-content: flex-end;
}
.justify-space-between {
  justify-content: space-between;
}
.align-items-end {
  align-items: flex-end;
}
.align-items-center {
  align-items: center;
}

.green-btn {
  background: var(--green-color) 0% 0% no-repeat padding-box;
  border: 1px solid var(--green-color);
}
.green-btn:hover, .green-btn:focus {
  background-color: #56B146c9;
  border: 1px solid var(--green-color);
}
.green-link-btn {
  color: var(--green2-color);
}
.bule-link-btn{
  color: var(--blue-primary-color);
}
.default-btn, .default-btn:hover{
  color: #178CD3;
  border : 2px solid #178CD3;
  border-radius: 5px;
}

.primary-btn{
  border-radius: 5px;
}

.add-btn, .add-btn:active, .add-btn:hover, .add-btn:focus{
  color: white;
  background: #55B247 0% 0% no-repeat padding-box;
  border : 1px solid white;
  border-radius: 5px;
}

// ANTD Input customizations
.ant-input{
  border: none;
  border-bottom: 1px solid #d9d9d9;
  background-color: rgba(0, 0, 0, 0);
}
.ant-input:hover { border: none; border-bottom: 1px solid #40a9ff; }
.ant-input:focus { box-shadow: none; }

// antd select customizations
.ant-select:not(.ant-select-customize-input) .ant-select-selector{
  border: none;
  border-bottom: 1px solid #d9d9d9;
  background-color: rgba(0, 0, 0, 0);
}
.ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
  background-color: rgba(0, 0, 0, 0);
  border-color: #ff4d4f !important;
}
.ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-open .ant-select-selector, .ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-focused .ant-select-selector {
  box-shadow: none;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
}
.ant-select-selection-overflow {
  position: relative;
  display: flex;
  flex: none;
  flex-wrap: nowrap;
  max-width: 100%;
  overflow: hidden;
}

// antd Datepicker customizations
.ant-picker{
  border: none;
  border-bottom: 1px solid #d9d9d9;
  background-color: rgba(0, 0, 0, 0);
}
.ant-picker-focused {  box-shadow: none; }


// table customization
.ant-table-tbody > tr > td ,.ant-table-thead > tr > th{
  border-bottom: 0.54px solid rgba(184, 184, 185);
}
.ant-table-body{
  border-bottom: 0.54px solid rgba(184, 184, 185);
  border-right: 0.54px solid rgba(184, 184, 185);
  border-left: 0.54px solid rgba(184, 184, 185);
}
.ant-table-header{
  border-radius: 10px 10px 0px 0px;
  border-top: 0.54px solid rgba(184, 184, 185);
  border-right: 0.54px solid rgba(184, 184, 185);
  border-left: 0.54px solid rgba(184, 184, 185);
}

// table pagination 


.ant-pagination-item{
  border: 1px solid #A0A1A2;
  border-radius: 4px;
}
.ant-pagination-item a{
  color: #6DAF55;
}
.ant-pagination-item-active {
  background: #6DAF55 0% 0% no-repeat padding-box;
  border-radius: 4px;
  border-color: #fff;
}
.ant-pagination-item-active a {
  color: #fff;
}
.ant-pagination-item-active:focus-visible a, .ant-pagination-item-active:hover a {
  color: #fff;
  border: none;
}
.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
  border-radius: 4px;
  color: #6DAF55;
}

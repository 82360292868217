@import '../../styles/mixins';

.home-custom-menu {
  padding: 6px 0;
  margin-bottom: 6px;
  height: auto;
  background: transparent;
  .menu-list {
    border: 0;
    color: #000000;
    // background: #e7e7e7;
     //new pc design's backgroud color
     background-color: #FFFFFF;
    .menu-item {
      a {
        color: #000000;
      }
    }
  }
  .menu-list.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after, 
  .menu-list.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after, 
  .menu-list.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after {
    border-color: var(--blue-color);
    bottom: -6px;
  }

  li.ant-menu-item {
    // display: block !important;
    padding: 8px 0px !important;
    height: 40px;
    line-height: 18px;
    &.ant-menu-item-selected .ant-menu-title-content a {
      color: var(--blue-color);
    }

    span {
      display: block;
      text-align: center;
      &.ant-menu-item-icon {
        font-size: 18px;
      }
      &.ant-menu-title-content {
        font-size: 10px;
        margin: 0;
      }
    }
  }
  .ant-menu-inline.ant-menu-root .ant-menu-item, .ant-menu-inline.ant-menu-root .ant-menu-submenu-title{
    display: block;
  }
}
@media screen and (max-width: 800px) {
  // .home-custom-menu li.ant-menu-item:nth-last-child(-1n+2){
  //   display: none !important;
  // }
  .mobile-hidden {
    display: none !important;
  }
}

@import '../../styles/mixins';

.start-of-day-email-modal {
  .ant-modal-content {
    padding: 20px 30px;
    border-radius: 16px;
    .ant-modal-header {
      padding: 0;
      .ant-modal-title {
        color: #194F72;
        font-size: 20px;
        text-transform: uppercase; 
        font-weight: 400;
      }
    }
    .ant-modal-body {
      border: 0;
      box-shadow: unset;
      padding: 20px 0;
      .subs-upload-btn {
        width: 400px;
        color: #666666;
        margin: 6px 0;
        padding: 16px 24px;
        box-sizing: content-box;
        font-size: 24px;
        text-align: left;
        .anticon {
          font-size: 32px;
          padding-right: 24px; 
        }
        a {
          color: inherit;
        }
      }
    }
  }
  .ant-table-thead > tr > th {
    background-color: #E3F3FC;
    color: #194F72;
    font-weight: 700;
    padding: 8px 16px;
    overflow-wrap: unset;
  }
  .btn-footer {
    padding-top: 20px;
  }
  .ant-table-tbody > tr > td {
    padding: 8px 8px
  }
}

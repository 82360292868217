@import '../../styles/mixins';

.manage-subs-maintain-accounts-operate-modal {
  .ant-modal {
    top: 298px;
    left: 570px;
    width: 780px;
    height: 483px;
  }
  .account-header-row {
    margin-bottom: 24px;
    .ant-form-item {
      flex-wrap: nowrap;
    }
  }
  .ant-form-item-control-input-content {
    display: flex;
    align-items: center;
    .ant-form-item {
      margin-bottom: 0;
    }
  }
  .series-tips-content-items-1 {
    top: 26px;
    left:66px;
  }
  .tips-content-item {
    white-space: nowrap;
    position: absolute;
    top: 16px;
    &.site-tip {
      left: 74px;
      top: 22px;
    }
    &.concept-tip {
      left: 70px;
      top: 25px;
    }
    &.edit-concept-tip {
      left: 97px;
    }
    &.edit-site-tip {
      left: 52px;
    }  
  }
  .ant-switch {
    background-color: #F0D8D8;
    .ant-switch-handle::before{
      background-color: red;
    }
  }
  .ant-switch-checked {
    background-color: #E3F4DE;
    .ant-switch-handle::before{
      background-color: var(--checkbox-green);
    }
  }
  .non-responsive-switch{
    background-color: #3A8BD3;
    .ant-switch-handle::before{
      background-color: #fff;
    }
  }
  .toggle-desc {
    line-height: 32px;
  }
  .cut-off-time {
    .ant-form-item-control {
      padding-left: 14px;
    }
    .ant-form-item-explain-error {
      margin-left: -26px;
    }
  }
}
.ant-picker-footer {
  .ant-picker-ok {
    display: none;
  }
}
@import '../../styles/mixins';

.manage-subs-manage-outs-add-sub {
  .ant-modal-header .ant-modal-title {
    display: none;
  }
  .ant-modal-content .ant-modal-body{
    padding: 0px 0px;
  }
  .ant-form-item{
    margin-bottom: 0px;
  }
  .series-tips{
    margin-left: 20px;
    margin-top: -6px;
  }
  .series-tips-content-items, .series-tips-content-items-1, .series-tips-timestamp-items {
    white-space: nowrap;
    .ant-form-item-control-input {
      min-height: unset;
      line-height: 1;
    }
    .tips-content-item {
      position: static;
    }
  }
  .series-tips-content-items-1{
    margin-top: 5px;
  }
  .tips-content-item {
    white-space: nowrap;
    position: absolute;
    top: 16px;
    
    &.site-tip {
      left: 3px;
      top: 17px;
      
    }
    &.concept-tip {
      left: 4px;
      top: 17px;
      
      
    }
    &.aosh-tip {
      left: 4px;
      top: 17px;
      
      
    }
    
  }
  
  .static-form-item-value label.custom-form-item-value{
    color: #666666;
    font-size: 15px;
    font-weight: 900;
  }
  label.custom-form-item-value {
    // font-size: 14px;
    // font-weight: 700;
    color: #666666;
    font-size: 12px;
    //font-weight: 600;
  }
  .static-form-item-value span {
    font-size: 20px;
    border: 1px solid black;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
  }
  .ant-carousel .slick-slider {
    position: sticky;
  }
  .ant-carousel .slick-track {
    left: -3px;
  }
  .ant-carousel .slick-prev,
  .ant-carousel .slick-prev:hover,
  .ant-carousel .slick-prev:focus {
    font-size: inherit;
    // left: 10px;
    z-index: 2;
    color: #707070;

    border: 1px solid #808080;
    border-radius: 4px;
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .ant-carousel .slick-next,
  .ant-carousel .slick-next:hover,
  .ant-carousel .slick-next:focus {
    font-size: inherit;
    // right: 10px;
    z-index: 2;
    color: #707070;

    border: 1px solid #808080;
    border-radius: 4px;
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
      
  .product-card{
    display: inline-block;
    padding-left: 13px;
  }
  .prodcuts-title{
    color: #194F72;
    font-size: 19px;
    margin-top: 15px;
    margin-bottom: 15px;
    font-weight: 700;
    padding-left: 32px;
  }
  .add-sub-search-form{
    margin-top: 25px;
    margin-bottom: 20px;
    margin-left: 10px;
    .ant-form-item{
      width: 136px;
    }
    .ant-btn{
      padding: 0px 10px;
      height: 28px;
    }
  }
  .reset-btn {
    border-color: #40a9ff;
    margin-right: 4px;
    border-radius: 2px;
  }
}
.manage-subs-manage-outs-add-sub .ant-modal-content{
  padding: 10px 36px;
}

@import '../../styles/mixins';

.manage-subs-manage-substitutions-operate-modal, .manage-subs-maintain-accounts-operate-modal,.manage-subs-customer-group-operate-modal,.manage-subs-add-customer-operate-modal {
  .ant-modal-content {
    padding: 20px 30px;
    border-radius: 16px;
    .ant-modal-header {
      padding: 0;
      .ant-modal-title {
        color: #194F72;
        font-size: 20px;
        text-transform: uppercase; 
        font-weight: 400;
      }
    }
    .ant-modal-body {
      border: 0;
      box-shadow: unset;
      padding: 20px 0;
      .subs-upload-btn {
        width: 400px;
        color: #666666;
        margin: 6px 0;
        padding: 16px 24px;
        box-sizing: content-box;
        font-size: 24px;
        text-align: left;
        .anticon {
          font-size: 32px;
          padding-right: 24px; 
        }
        a {
          color: inherit;
        }
      }
    }
  }
  .toggle-desc {
    line-height: 32px;
  }
  .sub-item-list-header {
    margin-top: 36px;
    background: #E6F2FB;
    color: #194F72;
    font-weight: 700;
    justify-content: space-between;
    border: 1px solid #64A9D7;
    border-radius: 10px 10px 0px 0px;
    .ant-col {
      line-height: 28px;
    }
  }
  .sub-item-list-item {
    padding: 6px 0px 33px 0px;
    border: 0.55px solid #c1c1c2;
    border-top: 0;
    color: #666666;
    justify-content: space-between;
    &.draggle-item {
      cursor: move;
    }
    &.dragging {
      opacity: 0;
    }
    .series-tips-content-items {
      top: 16px;
      z-index: 1;
    }
    .series-tips-content-items-1 {
      top: 26px;
      z-index: 1;
    }
    input {
      font-weight: 600;
      font-size: 12px;
      color: #666666;
      line-height: 22px;
    }
    .sub-item-position {
      input {
        padding: 0 11px 8px 11px;
      }
      .ant-form-item-control-input {
        // align-items: flex-start;
          label {
            padding-left: 11px;
          }
      }
    }
  }
  .sub-item-header, .sub-item-list-item {
    .ant-col .ant-form-item {
      margin: 0;
    }
  }
  .sub-item-header {
    .ant-form-item-explain {
      // white-space: nowrap;
      z-index: 1;
    }
  }
  .form-label{
    color: #194F72;
    font-size: 14px;
    font-weight: 900;
  }
  .static-form-item-value label.custom-form-item-value {
    color: #666666;
    font-size: 15px;
    font-weight: 600;
  }
  label.custom-form-item-value {
    color: #666666;
    font-size: 12px;
  }
  .series-tips-content-items, .series-tips-content-items-1, .series-tips-timestamp-items {
    white-space: nowrap;
    position: absolute;
    top: 25px;
    padding: 0px 11px;
    .ant-form-item-control-input {
      min-height: unset;
      line-height: 1;
    }
    .tips-content-item {
      padding: 0 1px;
      position: static;
    }
  }
  .series-edit-tips-content-items, .series-edit-tips-content-items-1{
    white-space: nowrap;
    position: absolute;
    top: 25px;
    padding: 0px 11px;
   
    .ant-form-item-control-input {
      min-height: unset;
      line-height: 1;
    }
    .tips-content-item {
      padding: 0 1px;
      position: static;
    }
  }
  .series-subs-tips-content-items, .series-subs-tips-content-items-1{
    white-space: nowrap;
    position: absolute;
    top: 25px;
    padding: 0px 11px;
   
    .ant-form-item-control-input {
      min-height: unset;
      line-height: 1;
    }
    .tips-content-item {
      padding: 0 1px;
      position: static;
      
    }
  }
  .series-tips-timestamp-items {
    //top: 22px;
    font-size: 2px;
  }
  .series-subs-tips-content-items-1{
    top:48px;
    left:0px;
  }
  .series-subs-tips-content-items{
    top:32px;
    left:0px;
  }
  .series-tips-content-items{
    top:34px;
    left: 100px;
  }
  .series-tips-content-items-1 {
    top: 51px;
    left: 100px;
  }
  .series-edit-tips-content-items{
    left:80px;
  }
  .series-edit-tips-content-items-1{
    top:41px;
    left:80px;
  }
  .tips-content-item {
    white-space: nowrap;
    position: absolute;
    top: 16px;
    &.site-tip {
      left: 74px;
      top: 22px;
    }
    &.concept-tip {
      left: 60px;
      top: 25px;
    }
    &.edit-concept-tip {
      left: 95px;
    }
    &.edit-site-tip {
      left: 52px;
    }
  }
  .subs-switch {
    margin: 0px 6px 4px 6px;
    width: 34px;
    transform: rotate(180deg);
  }
  .move-btn:hover {
    color: #285579;
  }
  .no-border{
    border-color: rgba(255, 255, 255, 0);
  }
}

.subs-footer{
  padding-top: 20px;
}
.sub-neversub-btn{
  //padding-left: 40px !important;
}
.uom-dropdown{
  padding-left: 10px !important;
}
.createdByName{
  padding:0px 11px;
}
@import '../../styles/mixins';

.manage-subs-manage-outs-warning-modal {
  text-align: center;
  .ant-modal-content {
    padding: 32px 36px;
    border-radius: 16px;
    .ant-modal-header {
      padding: 0;
      .ant-modal-title {
        color: #194F72;
        font-size: 28px;
        text-transform: uppercase; 
        font-weight: 400;
      }
    }
    .ant-modal-body {
      border: 0;
      box-shadow: unset;
      padding: 36px 0;
    }
    .ant-modal-footer {
      border-top: 0px;
    }
    .content-title{
      text-align: center;
      font-size: 20px;
      color: #194F72;
      // color:gold
    }
    svg{
      padding-left: 5px;
    }
  }
}

.reason-code-modal {
  text-align: left;
  .ant-modal-content {
    padding: 32px 30px;
    padding-bottom: 10px;
    .ant-modal-body {
      padding: 10px 0;
    }
    .ant-modal-footer {
      padding: 10px 16px;
      padding-top: 20px;
    }
  }
}

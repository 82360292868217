@import '../../styles/mixins';

.manage-subs-manage-subs {

  .manage-subs-menu {
    background-color: #f7f7f9;
    background-image: url("../../assets/SYSCO_TRUCK.svg");
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: 135% 162%;
  }
  .subs-layout-content {
    background-color: #ffffffcf;
    min-height: calc(100vh - 56px);
    max-width: calc(100vw - 260px);
    width: calc(100vw - 260px);
    padding: 24px 18px;
    min-height: 570px !important;
  }
  .collapsed-menu-content{
    max-width: 98%;
    width: 98%;
  }
  .collapse-btn{
    //position: absolute;
    left: -3px;
    top: -1px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    padding: 0px;
  }
  .collapse-btn2{
    
      display: inline-block;
      //width: 5px;
      height: unset;
      visibility: hidden;
      padding: 0%;
      cursor:default;
      // content: '\A0';
  
  }
}

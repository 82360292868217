@import '../../styles/mixins';

.reports-new-reports-layout-content {
    width:calc(100vw - 88px);
    padding: 10px 0px 0px 10px;
    
}
@media screen and (max-width: 800px) {
    .reports-new-summary {
        width:100vw;
        padding: 10px;
    }
    .reports-new-reports-layout-content{
        width:100%;
        padding:10px;
    }
}
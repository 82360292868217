@import '../../styles/mixins';

.dashboard-expected-sales-card {
  height: 70%;
  
  ul{
    margin-top:35px;
    padding-inline-start: 0px;
    padding-inline-end: 20px;


    display: flex;
    flex-direction: column;
    height: 100%;

    li{
      text-align: right;
      display: flex;
      justify-content: space-between;
      height: 100%;
      div.sub:first-child{
        width:100px;
      }
      div.sub:nth-child(2n){
        width:146px;
      }
      div.sub:last-child{
        width:132px;
      }
    }
    .one-sub-02,.two-sub-02,.three-sub-02{
      font-weight: bold;
      margin-top:8px;
      color: #292929;
      height: 60%;
    }
    .one-sub-01,.two-sub-01,.three-sub-01{
      color: #6A737B;
      height: 60%;
    }
  }
}
.filter-desc {
  .ant-descriptions-view {
    // border: 1px solid #dcdada;
    // border-radius: 3px;
    padding: 9px;
    // box-shadow: 0 2px 8px 0 rgba(0,0,0,0.2);
    table {
      table-layout: auto;
      .ant-descriptions-row > th {
        padding-bottom: 2px;
      }
      .ant-descriptions-row > td {
        padding-bottom: 8px;
        height: 31px;
      }
    }
    .ant-descriptions-item-container .ant-descriptions-item-label, .ant-descriptions-item-container .ant-descriptions-item-content{
      // display: unset;
      white-space: nowrap;
    }
    .ant-descriptions-item-label::after {
      content: unset;
    }
  }
}
@media screen and (max-width: 800px) {
  .sia-filter-desc-mobile {
    .ant-descriptions-item-container .ant-descriptions-item-label, .ant-descriptions-item-container .ant-descriptions-item-content{
      // display: unset;
      white-space: space warp !important;
    }
  }
  .filter-desc .ant-descriptions-view .ant-descriptions-item-container .ant-descriptions-item-label, .filter-desc .ant-descriptions-view .ant-descriptions-item-container .ant-descriptions-item-content{
    white-space: space warp !important;
  }
  .dashboard-expected-sales-card {
    ul{
      margin-top:20px;
      padding-inline-start: 10px;
      padding-inline-end: 10px;
      li{
        text-align: right;
        height: 86px;
        div.sub:first-child{
          width:82px;
          text-align: left;
        }
        div.sub:nth-child(2n){
          width:110px;
        }
        div.sub:last-child{
          width:110px;
        }
        .one-sub-02,.two-sub-02,.three-sub-02{
          font-weight: bold;
          margin-top:8px;
          color: #292929;
        }
        .one-sub-01,.two-sub-01,.three-sub-01{
          color: #6A737B;
        }
      }
    }
  }
}

@import '../../styles/mixins';

.dashboard-order-health-check-card {
  padding-left: 15px;
  .health-check-row {
    // background: #ececec;
    // padding: 10px 16px;
    // &:nth-child(2) {
    //   margin: 0 12px;
    // }
    cursor: pointer;
    margin-right: 24px;
    .green-card {
      border-left-color: var(--green-color);
      color: var(--green-color);
    }
    .orange-card {
      border-left-color: var(--orange-color);
      color: var(--orange-color);
    }
    .red-card {
      border-left-color: var(--red-color);
      color: var(--red-color);
    }
  }
}
@media screen and (max-width: 800px) {
  .dashboard-order-health-check-card {
    padding-left:8px;
    padding-right: 8px;;
    .health-check-row {
      // background: #ececec;
      padding: 10px 8px;
      &:nth-child(2) {
        margin: 0;
        border-top:1px solid #E4E5E6;
        border-bottom:1px solid #E4E5E6;
      }
    }
    .sub02{
      // margin-top:16px;
      // padding-top:16px;
      border-top:1px solid #E4E5E6;
    }
  }
}
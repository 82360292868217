@import '../../styles/mixins';

.dashboard-exceptions-details {
    padding-left: 15px;
    .exceptions-details-row {
        cursor: pointer;
        margin-right: 5%;
        .orange-card {
            border-left-color: var(--orange-color);
            color: var(--orange-color);
        }
        .primary-card {
            border-left-color: var(--blue-primary-color);
            color: var(--blue-primary-color);
          }
    }
}

@media screen and (max-width: 800px) {
    .dashboard-exceptions-details {
        padding-left: 15px;
        padding-right: 15px;
    }
}
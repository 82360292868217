@import '../../styles/mixins';

.manage-subs-manage-outs {
  .chart-title {
    color: var(--blue-heading-color);
  }
  .handle-overflow{
    overflow: hidden;
    white-space: nowrap;
  }
  .sub-badge-item {
    margin-left: 5px; 
    .ant-badge-status-dot {
      width: 10px;
      height: 10px;
    }
  }
  
  .blank-badge {
    margin-left: 23px;
  }
  .subs-header-btn {
    color: #285579;
    // border-color: #285579;
    margin: 4px 2px;
  }
  .parent-actions .ant-btn {
    color: #1A1A1A;
  }
  // .parent-actions{
  //   .ant-btn-link{
  //     width: 20px;
  //   }
  // }
  .report-pagination {
    margin-bottom: 4px !important;
  }
  .detail-info {
    margin-left: 20px;
  }
  .outs-switch {
    margin: 0px 6px 4px 6px;
    width: 32px;
    transform: rotate(180deg);
  }
  .optimization-btn{
    // position: absolute;
    // bottom: -500%;
    margin-left: -10px;
  }
}
.outs-report-table .ant-table-body {
  max-height: calc(100vh - 335px) !important;
}
.outs-report-table {
  .ant-table-tbody > tr > td {
    padding: 0px 8px;
    // border-bottom: 1px solid #0071BC;
    // border-right: 1px solid #0071BC;
  }
  .ant-table-tbody > tr:nth-child(2) > td {
    // border-top: 1px solid #0071BC;
  }
  .ant-table-tbody > tr > td:first-child {
    // border-left: 1px solid #0071BC;
  }
  // .expanded-report-table {
  //   .ant-table-thead > tr > th {
  //     padding: 4px 8px;
  //   }
  //   .ant-table-tbody > tr > td {
  //     padding: 4px 8px;
  //     border: 0;
  //     border-bottom: 1px solid #F2F2F2;
  //   }
  // }
  .ant-btn{
    text-align: left;
  }
  
}
.col-title {
  font-weight: 700;
  font-size:12px;
}
.col-desc {
  font-family: MyriadPro, sans-serif;
  font-size: 12px;
  color: #949494;
  font-weight: 10;
  margin-top: -2px;
}

@import '../../styles/mixins';

.home-layout {
  .custom-menu-sider {
    background: #ffffff;
    width: 60px;
  }
  .layout-container {
    // background: #ededed;
    //new pc design's backgroud color
    background-color: #FFFFFF;
  }
  .layout-container {
    // background-color: #ffffffcf;
    //new pc design's backgroud color
    background-color: #E8E8E8;
    min-height: calc(100vh - 56px);
    width: 100%;
  }
  .feedback {
    position: absolute;
    bottom: 40px;
  }
  .feedback-icon {
    font-size: 18px;
    color: #000000;
  }
  .feedback-icon:hover {
    color: #008CD2;
  }
}
.is-show-mobile{
  display:none !important;
}
.is-show-pc{
  display:block !important;
}
.sys-mark{
  background: rgba(42, 47, 51, 0.7);
  position: fixed;
  width: 100vw;
  height: 100%;
  top:5rem;
  left:0;
  z-index: 99998;
}
.overHiddenFixed{
  overflow: hidden;
  position: fixed;
}
@media screen and (max-width: 800px) {
  .is-show-mobile{
    display:block !important;
  }
  .is-show-pc{
    display:none !important;
  }
  .home-layout{
    padding-top:5rem;
  }
  .custom-menu-sider-none{
    position: absolute;
    z-index: 99999;
    height: 100%;
    left: -62px;
    }
  .custom-left-0{
    left:0px !important;
  }
}
@import '../../styles/mixins';

.manage-subs-site-concept-common-panel {

}

.siteID{
  .ant-form-item-control{
    overflow: hidden;
  }
}

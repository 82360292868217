@import '../../styles/mixins';

.order-trail-business-event-log {
  margin-top: 16px;
  padding: 0 8px 12px 8px;
  box-shadow: unset;
  border-radius: 3px;
  background-color: #FFFFFF;
  .chart-title {
    padding: 8px 0;
  }
  .event-col {
    word-break: break-all;
  }
  .order-banner-list {
    width: fit-content;
    padding: 4px 12px;
  }
  .order-banner-list:nth-child(2) {
    margin-top: -16px;
  }
  .order-banner-list:nth-last-child(2) {
    margin-bottom: 5px;
  }
  .ant-table-filter-trigger{
    color: #194F72;
  }
  .export-button {
    margin-top: -10px;
    .ant-btn:last-child {
      margin-left: 15px
    }
  }
}

@import '../../styles/mixins';

.is-show-mobile{
  display:none;
}
.is-show-pc{
  display:block;
}
.fa-bars-icon{
  transition: All 0.4s ease-in-out;
  -webkit-transition: All 0.4s ease-in-out;
  -moz-transition: All 0.4s ease-in-out;
  -o-transition: All 0.4s ease-in-out;
}
.roateRight{
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform:  rotate(180deg);
  -o-transform:  rotate(180deg);
  -ms-transform:  rotate(180deg);
}
.roateLeft{
  transform: rotate(-180deg);
  -webkit-transform: rotate(-180deg);
  -moz-transform:  rotate(-180deg);
  -o-transform:  rotate(-180deg);
  -ms-transform:  rotate(-180deg);
}
.home-head-panel {
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding: 12px 20px;
  padding: 8px 20px;
  background-color: var(--blue-color);
  .header-left {
    display: flex;
    align-items: center;
    .sysco-logo {
      box-sizing: content-box;
      width: 96px;
      height: 36px;
      padding-right: 20px;
      background: url(../../images/sysco-logo-white.png);
      background-repeat: no-repeat;
    }
    .custom-divider {
      height: 4em;
      font-size: 10px;
      border-color: #ffffffd4;
      border-width: 2px;
      margin: 0 30px 0 20px;
    }
  }
  .header-right{
    font: 400 16px/17px MyriadPro, sans-serif;
  }
}
.header-help-popover {
  width: 180px !important;
  height: 200px;
  p {
    margin-top: 0;
    margin-bottom: 0;
  }
  .ant-popover-inner-content{
    padding: 10px 12px;
  }
}
@media screen and (max-width: 800px) {
  .is-show-mobile{
    display:block;
  }
  .is-show-pc{
    display:none;
  }
  .font-size-24 {
    font: 18px/20px MyriadPro, sans-serif !important;
}
  .home-head-panel {
    height: 5rem;
    position: fixed;
    width: 100%;
    z-index: 999;
    top:0;
    padding: 8px 10px;
    .header-left {
      .sysco-logo {
        box-sizing: content-box;
        width: 6.2rem;
        height: 2.3rem;
        padding-right: 10px;
        background-size: 6.2rem 2.3rem;
        margin-left:1rem;
      }
      .custom-divider {
        height: 1.4rem;
        font-size: 1rem;
        border-color: #ffffffd4;
        border-width: 1px;
        margin: .2rem 1rem 0 0;
      }
    }
    .header-right{
      height: 5rem;
      display: flex;
      align-items: center;
      font: 400 12px/14px MyriadPro, sans-serif;
    }
  }
}
@import '../../styles/mixins';

.dashboard-order-inflow-trend {
  // border: 1px solid #dcdada;
  // border-radius: 3px;
  // box-shadow: 0 2px 8px 0 rgba(0,0,0,0.2);
  height: 100%;
  display: flex;
    align-items: center;
  #order-inflow-trend {
    min-height: 200px;
    width: calc((100vw - 282px) / 2);
  }
}
@media screen and (max-width: 800px) {
  .dashboard-order-inflow-trend {
    #order-inflow-trend {
      min-height: 188px;
      width: 100% !important;
    }
  }
}
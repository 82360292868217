@import '../../styles/mixins';

.dashboard-tag-card {
  width: 100%;
  border: 1px solid #dcdada;
  border-left: 5px solid;
  border-radius: 4px;
  background: #ffffff;
  margin: 6px 0;
  cursor: pointer;
  .card-title, .card-count {
    padding: 8px 16px; 
  }
  .card-title {
    color: #000000;
  }
}

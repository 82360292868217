@import '../../styles/mixins';
.reports-new-tables {
    margin: 10px 15px 10px 6px;
    background: #FFFFFF;
    box-shadow: 1px 1px 4px rgba(106, 115, 123, 0.15);
    border-radius: 8px;
    overflow: hidden;
    .reports-result-panel {
        padding: 10px;
        background-color: #FFFFFF;

        

        .ant-modal-content {
            // padding: 15px 10px;
        }

        .ant-modal-header {
            border: 0;
            // .ant-modal-title {
            //   font-size: 14px;
            //   font-weight: 600;
            //   display: flex;
            //   align-items: center;
            // }
        }

        // .ant-modal-body {
        //     box-sizing: border-box;
        //     border: 1px solid #979797;
        //     box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        //     // padding: 20px 10px;
        // }

        .report-new-table {
            .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
                position: absolute;
                top: 50%;
                right: 0;
                width: 0px;
                height: 0em;
                background-color: rgba(0, 0, 0, 0.06);
                transform: translateY(-50%);
                transition: background-color 0.3s;
                content: '';
            }
            .ant-table-tbody>tr>td,
                .ant-table-thead>tr>th {
                    border-bottom: 0.54px solid #E4E5E6;
                }
            .ant-table-body{
                border-bottom: none;
            }
            .ant-table-header,.ant-table-body {
                border-radius: none;
                border-top: none;
                border-right: none;
                border-left: none;
            }
            .ant-table-thead>tr>th {
                // box-shadow: 0 1px 0 0 rgb(0 0 0 / 85%);
                padding: 6px 0px;
                font-weight: 400;
                text-align: center;
            }

            .ant-table-tbody>tr>td {
                padding: 6px 0px;
                text-align: center;
            }

            .report-link {
                color: var(--blue-primary-color);
                text-decoration: underline;
            }

            .report-status-normal {
                color: var(--green-color);
                text-transform: uppercase;
            }

            .report-status-error {
                color: var(--red-color);
                text-transform: uppercase;
            }

            .report-status-warning {
                color: var(--orange-color);
                text-transform: uppercase;
            }

        }
    }
}
.report-pagination {
    padding-top: 16px;
    padding-bottom: 16px;
    .ant-pagination-item-link {
        border: 0px;
    }

    .ant-pagination-item {
        border: 1px solid #E4E5E6;
        border-radius: 4px;
    }

    .ant-pagination-item a {
        color: #008CD2;
    }

    .ant-pagination-item-active {
        background: #008CD2 0% 0% no-repeat padding-box;
        border-radius: 4px;
        border-color: #fff;
    }

    .ant-pagination-item-active a {
        color: #fff;
    }

    .ant-pagination-item-active:focus-visible a,
    .ant-pagination-item-active:hover a {
        color: #fff;
        border: none;
    }

    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
        border-radius: 4px;
        color: #008CD2;
    }

    .ant-pagination-disabled .ant-pagination-item-link {
        color: #A1A1A1;
    }
}
.tit-right{
    text-align: right !important;
  }
.last-col-padding{
    text-align: right !important;
    padding-right: 8px !important;
}
  .title-white-sapce {
    .ant-btn-link >span{
        white-space: normal;
        word-break: break-all;
      }
  }
.sia-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    // margin-bottom: 10px;
}
@media screen and (max-width: 800px) {
    .sia-container{
        display: block;
    }
    .reports-new-tables{
        margin:0px;
        box-shadow: none;
        .reports-result-panel{
            padding:0;
            background: none;
        }
    }
    .reports-mobile-list{
        background-color: #fff;
        max-height:400px ;
        overflow-y:scroll;
        box-shadow: 1px 1px 4px rgba(106, 115, 123, 0.15);
        border-radius: 8px;
        padding-top:12px;
        margin-top: 5px;
        .reports-no-data{
            padding-top:80px;
            padding-bottom: 80px;
            text-align: center;
            color: #AAACAE;
        }
        ul {
            margin-left:15px;
            margin-right: 15px;
            margin-bottom: 8px;
            padding-bottom:8px;
            padding-left:12px;
            border-bottom: 1px solid #E4E5E6;
            li{
                display: flex;
                justify-content:space-between;
                align-items: center;
                height: 32px;
                padding-right: 15px;
                .sub01{
                    width: 100px;
                    font-size: 14px;
                    color: #6A737B;
                    margin-right: 26px;
                }
                .sub02{
                    font-size: 14px;
                    color: #292929;
                }
            }
        }
    }
}
@import '../../styles/mixins';

.order-trail-snapshot-order-details {
  border: 1px solid #1b4f72;
  .snapShotStyl {
    background-color: #e6f7ff !important;
  }
  .orderdetail {
    .ant-table-body {
      height: 310px;
    }
  }
  .sub-tag {
    font-size: 10px;
    line-height: 1;
    padding: 2px 4px;
    margin-left: 4px;
  }
}

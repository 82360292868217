@import '../../styles/mixins';

.common-report-table {
  .greyed{
    background-color: #e9e9e9;
    color: #949494;
  }
  .ant-table-tbody > tr.greyed > td {
    background-color: #e9e9e9;
  }
  .ant-table-tbody > tr.greyed:hover > td {
    background-color: #f1f1f1;
  }
  .ant-table-tbody > tr > td {
    height: 70px;
  }
  .ant-table-thead > tr > th {
    background-color: #E3F3FC;
    color: #194F72;
    font-weight: 700;
    padding: 8px 16px;
    overflow-wrap: unset;
    .ant-table-column-sorter {
      color: #194F72;
    }
  }
  // .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
  //   position: relative;
  //   padding: 16px 16px;
  //   overflow-wrap:unset;
  // }
  .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table {
    margin: -4px 10px -4px 41px;
  }
  //fixed initial table column width if there are these columns
  .ant-table-thead > tr {
    & > th.ant-table-row-expand-icon-cell {
      width: 48px;
    }
    & > th.ant-table-cell-scrollbar {
      width: 17px;
    }
  }
}
.expanded-report-table {
  margin: 3px 20px 4px 20px;
  .ant-table-thead > tr > th {
    background-color: #f2f2f2;
    padding: 6px 16px;
    color: #000000;
    font-weight: 400;
  }
}
.timeout-modal {
  .ant-modal-content{
    padding: 0px;
    border-radius: 5px;
  }
  .ant-modal-body {
    border: unset;
    box-shadow: unset;
  }
  .ant-btn {
    border-radius: 5px;
  }
}

@import '../../styles/mixins';

.dashboard-dashboard {
  .reports-new-header{
    display: none;
  }
  padding-top: 16px;
  padding-left: 16px;
  .chart-panel-sub01{
    display: flex;
    justify-content: space-between;
    min-height: 45%;
  }
  .customizations-layout-sider{
    display: flex;
    justify-content: space-between;
  }
  .sub-layout-content{
    flex:1;
  }
  .ant-layout{
    background-color: unset;
  }
  .ant-layout-sider{
    background:none !important;
  }
  .chart-title{
    padding:12px 0px 5px 12px;
  }
}
.sub-layout-content {
  padding: 0 12px;
  // box-shadow: 2px 0 6px 2px rgba(0, 0, 0, 0.2);
  z-index: 1;
  // background: #ffffff;
}
.dashboard-dashboard-content-box{
  background: #FFFFFF;
  box-shadow: 1px 1px 4px rgba(106, 115, 123, 0.15);
  border-radius: 8px;
  margin-bottom:15px;
  padding-bottom:10px;
 
}
.dashboard-dashboard-content-box-sub01{
  width: 45.8%;
}
.dashboard-box-sub01{
 width:46.2%
}

@media screen and (max-width: 800px) {
  .filter-but-right-mobile{
    position: absolute;
    right: 26px;
    top:16px;
    color: #008CD2;
  }
  .dashboard-box-sub01{
    width:100%
   }
  .dashboard-dashboard{
    padding:0px;
    .customizations-layout-sider{
      display: block !important;
    }
    .chart-panel-sub01{
      display: block !important;
    }
    .ant-row{
      display: block !important;
    }
    .ant-col-5,.ant-col-6{
      width:100% !important;
      max-width: 100%;
    }
    .ant-col-11{
      width: 100% !important;
      max-width: 100%;
    }
    .ant-layout-sider{
      padding: 0px 12px;
    }
  }
  .dashboard-dashboard-content-box-sub01{
    width: 100%;
  }
}
.ant-notification-top{
  margin-top: 120px;
}
.dashboard-notification {
  width: 400px;

  .ant-notification-notice-btn {
    float: unset;
    margin-top: 0px;
  }
  .notification-msg{
    font: 500 18px/20px MyriadPro, sans-serif;
  }
}
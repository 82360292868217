@import '../../styles/mixins';

.reports-new-export-button {
    // margin-top: -50px;
    // margin-bottom: 20px;
    margin-right: 20px;
    .ant-btn:last-child {
        margin-left: 15px
    }

    .ant-btn {
        height: 30px;
        border-radius: 20px;
        span:last-child{
            font-size: 14px;
             margin-left: 8px;
        }
    }

    .btn-border-color {
        border-color: #008CD2;
    }

    .font-icon {
        border: 1px solid #008CD2;
        color: #008CD2;

    }

    .font-icon-disabled {
        border: 1px solid #a1a1a2;
        color: #a1a1a2;
    }
}
.sia-export-top-but{
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 800px) {
    .reports-new-export-button {
        // margin-top: -50px;
        // margin-bottom: 20px;
        margin-right: 20px;
        .ant-btn:last-child {
            margin-left: 8px
        }
    
        .ant-btn {
            width: 100%;
            height: 48px;
            border-radius: 34px;
            line-height: 48px;
        }
    
        .btn-border-color {
            border-color: #008CD2;
        }
    
        .font-icon {
            border: 1px solid #008CD2;
            color: #008CD2;
    
        }
    
        .font-icon-disabled {
            border: 1px solid #a1a1a2;
            color: #a1a1a2;
        }
    }
}
@import '../../styles/mixins';

.feedback-modal {
  .ant-modal-content {
    padding: 10px 20px;
    border-radius: 16px;
    .ant-modal-header {
      padding: 0;
      .ant-modal-title {
        color: #194F72;
        font-size: 20px;
        text-transform: uppercase; 
        font-weight: 400;
      }
    }
    .ant-modal-body {
      border: 0;
      box-shadow: unset;
      padding: 20px 0 10px 0;
      .title {
        font-weight: 900;
        font-size: 20px;
      }
      .desc {
        font-size: 16px ;
      }
      .ant-rate-star-first, .ant-rate-star-second {
        color: #d5d5d5;
      }
      .ant-rate-star-full {
        .ant-rate-star-first, .ant-rate-star-second {
          color: #fadb14;
        }
      }
      .text-feedback {
        margin-top: 10px;
      }
      .ant-input {
        border: 1px solid #d9d9d9;
      }
      .ant-input:hover { border: 1px solid #40a9ff; }
      .ant-input:focus { box-shadow: none; }

      .ant-radio-group{
        margin-left: 60px;
      }
      .ant-radio-button-wrapper:first-child {
        border-left: unset;
      }
    
      .ant-radio-button-wrapper{
        border: unset
      }
      .ant-radio-button-wrapper > .ant-radio-button{
        width: 40px;
      }
      .ant-radio-button-wrapper:focus-within {
        box-shadow: unset;
      }
      .ant-radio-button-wrapper:not(:first-child)::before {
        display:none
      }
      .ant-radio-button-wrapper{
        width: 40px;
        padding: 0px;
      }
    }
  }
  .btn-footer {
    padding-top: 20px;
  }
  .ant-table-tbody > tr > td {
    padding: 8px 8px
  }
}
.feeback-color {
  color: #DDDDDD;
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color:#DDDDDD
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover{
    color:#DDDDDD
  }
  .ant-radio-button-checked{
    color:#DDDDDD
  }
  .ant-radio-button-wrapper .ant-radio-button-wrapper-checked {
    color:#DDDDDD
  }
  .ant-radio-button-wrapper:hover {
    color: #DDDDDD;
  }
  .ant-radio-button-wrapper-checked:focus-within{
    color: #DDDDDD;
  }
  .ant-radio-button-wrapper:focus-within{
    color: #DDDDDD;
  }
  .ant-radio-button-checked:focus{
    color: #DDDDDD;
  }
  .ant-radio-button-wrapper-checked:hover{
    color: #DDDDDD;
  }
}
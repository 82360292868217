@import '../../styles/mixins';

.dashboard-order-by-type-card {
  padding-left: 15px;
  .primary-card {
    border-left-color: var(--blue-primary-color);
    color: var(--blue-primary-color);
  }
  .order-type-row {
    cursor: pointer;
    margin-right: 24px;
  }
}
@media screen and (max-width: 800px) {
  .dashboard-order-by-type-card {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@import '../../styles/mixins';

.reports-new-total-panel {
    padding-right: 10px;
    min-height: 145px;
    .total-grid {
        // height: 120px;
    }

    .chart-item {
        //display max 5 total data one line
        width:calc((100vw - 188px) / 5);
        box-sizing: content-box;
        margin: 8px;
        background: #FFFFFF;
        box-shadow: 1px 1px 4px rgba(106, 115, 123, 0.15);
        border-radius: 8px;
        z-index: 1;
        background: #ffffff;
        border-radius: 5px;
        // height: 80px;
        &:first-child {}

        &:nth-child(2) {}

        .chart-item-panel {

            margin: 10px;

            &:last-child {
                padding-bottom: 0;
            }

            .chart-title {
                padding: 8px 0;
            }

            &:after {
                width: 200px;
            }

            p {
                text-align: center;
                margin-top: unset;
                margin-bottom: 10px;
            }

            .total-item-count {
                // padding-top: 2px;
                // padding-bottom: 2px;
            }

            .total-item-title {
                color: #979797;
            }
            .total-item-count-blank{
                margin-top:45px;
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .reports-new-total-panel {
        padding-right: 0px;
        height: 31rem;
        overflow-y: scroll;
        margin-bottom:8px;
        margin-top: 5px;
        .chart-item {
            //display max 5 total data one line
            width: 100%;
            box-sizing: content-box;
            margin:8px 0px 8px 0px;
            // padding: 0 12px;
            box-shadow: 1px 1px 4px rgba(106, 115, 123, 0.15);
            z-index: 1;
            background: #ffffff;
            height: 8rem;
    
            // height: 80px;
            &:first-child {}
    
            &:nth-child(2) {}
    
            .chart-item-panel {
    
                margin: 10px;
    
                &:last-child {
                    padding-bottom: 0;
                }
    
                .chart-title {
                    padding: 8px 0;
                }
    
                &:after {
                    width: 200px;
                }
    
                p {
                    text-align: center;
                    margin-top: unset;
                    margin-bottom: 10px;
                }
                .total-item-count{
                    // margin-top: 1.5rem;
                    margin-bottom:1rem;
                }
                .total-item-title {
                    color: #979797;
                }
                .total-item-count-blank{
                    margin-top: 4.5rem;             
                }
            }
        }
    }
}

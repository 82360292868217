@import '../../styles/mixins';

.reports-reports {
  width: calc(100vw - 62px);
  .chart-panel {
    .chart-item {
      box-sizing: content-box;
      .chart-item-panel {
        padding-bottom: 10px;
        .chart-title {
          padding: 8px 0;
        }
      }
    }
  }
}

@import '../../styles/mixins';

.manage-subs-search-filters-panel {
  position: relative;
  margin-top: 18px;
  .ant-form-item {
    margin-bottom: 4px;
  }
  .mult-valid-item {
    .ant-form-item-control-input-content {
      display: flex;
    }
  }
  .search-action {
    text-align: right;
  }
  .ant-radio-button-wrapper{
    border-radius: 15px;
    margin: 5px;
    border-left-width: 1px;
    color: #0f34eb;
    border-color: #d0d4d9;
    background: #d0d4d9;
    font-weight: 400;
    }
  .ant-radio-button-wrapper:not(:first-child)::before {
    width: 0px;
  }
  .ant-checkbox-wrapper{
    padding-top: 10px;
    padding-left: 10px;
  }
  .ant-form-item-label > label::after {
    content: unset;
  }
}
@import '../../styles/mixins';

.manage-subs-customer-group-operate-modal {
    // .ant-modal {
    //     top: 298px;
    //     left: 570px;
    //     width: 780px;
    //     height: 483px;
    //   }
      .ant-input-affix-wrapper{
        box-shadow: none;
        border: none;
        border-bottom: 1px solid #d9d9d9;
        background-color: rgba(0, 0, 0, 0);
      }
      .ant-input-affix-wrapper-focused{
        border: none;
        border-bottom: 1px solid #40a9ff;
        background-color: rgba(0, 0, 0, 0);
        box-shadow: none;
      }
      .ant-input-affix-wrapper:hover { border: none; border-bottom: 1px solid #40a9ff; }
      .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused{
        box-shadow: none;
      }
      .ant-input-prefix{
        margin-right: 0px;
      }
      .ant-input-affix-wrapper-status-error .ant-input-prefix{
        color: rgba(0, 0, 0, 0.85);
      }
      .input-uppercase >input{
        text-transform: uppercase;
       }

       .ant-form-item-control-input-content {
        display: flex;
        align-items: center;
        .ant-form-item {
          margin-bottom: 0;
        }
      }
      .ant-switch {
        background-color: #F0D8D8;
        .ant-switch-handle::before{
          background-color: red;
        }
      }
      .ant-switch-checked {
        background-color: #E3F4DE;
        .ant-switch-handle::before{
          background-color: var(--checkbox-green);
        }
      }
    .toggle-desc {
        line-height: 32px;
      }
      .cut-off-time {
        .ant-form-item-control {
          padding-left: 14px;
        }
        .ant-form-item-explain-error {
          margin-left: -26px;
        }
      }
      .non-responsive-switch{
        background-color: #3A8BD3;
        .ant-switch-handle::before{
          background-color: #fff;
        }
      }
      .ant-picker-footer {
        .ant-picker-ok {
          display: none;
        }
      }
}

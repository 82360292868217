@import '../../styles/mixins';

.reports-table-footer {
    .all-column{
        text-align: center !important;
        // padding-left: 50px;
    }
    // .ant-table-thead > tr > th {
    //     color: #ffffff;
    //     background-color: #1B4F72;
    //     box-shadow: 0 1px 0 0 #DDDBDA;
        // padding: 6px 16px;
    //   }
    //   .ant-table-tbody > tr > td {
    //     padding: 6px 0px;
    //   }
    .ant-table-content {
        overflow: hidden !important;
        // overflow-x: scroll;
        // z-index: 0;
        // &::-webkit-scrollbar {
        //     width: 0;
        //     height: 0;
        // }
    }
    .ant-table-body{
        overflow: hidden !important;
        border-bottom: 0px;
        border-top:0px;
        z-index: 0;
        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
    }
    .ant-table-tbody>tr>td {
        // padding: 8px;
        border-bottom: 0px;
        background: #ffffff;
    }
    .ant-table-tbody > tr.ant-table-row:hover > td,
    .ant-table-tbody > tr > td >.ant-table-cell-row-hover{
        background: #ffffff;
    }
    .ant-btn-link{
        cursor:none;
        font-weight: 700;
        color:rgba(0, 0, 0, 0.85);
        font-size:14px;
        pointer-events:none;
    }
}
.ant-table-footer {
    overflow-y: scroll;
    font-weight: 700;
    padding: 0px !important;
    position: relative;
    // top:-56px;
    z-index: 0;
  }  
  .ant-table-footer .ant-table-body{
   position: relative !important;
   z-index: 1 !important;
  } 
  .footer-padding{
    .ant-table-tbody > tr > td {
        padding: 8px 2px;
    }
}
.report-new-table .all-column{
    text-align: inherit;
}
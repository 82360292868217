@import '../../styles/mixins';

.order-trail-snapshot-report-modal {
  .ant-modal-content {
    padding: 0 !important;
    border-radius: 5px;
    .ant-modal-close {
      color: var(--green2-color);
      span {
        font-size: 20px;
      }
    }
    .ant-modal-body {
      border-radius: 5px;
      padding: 24px;
      .snapshot-download-btn,
      .snapshot-help-btn {
        margin-top: 10px;
        top: 0;
        position: absolute;
        span {
          font-size: 20px;
        }
      }
      .snapshot-download-btn {
        right: 90px;
      }
      .snapshot-help-btn {
        right: 55px;
        &:hover, &:visited, &:link {
          color: var(--green2-color);
        }
      }
      .snapshot-tabs {
        .ant-tabs-nav {
          margin: 18px 24px 0 24px;
          .ant-tabs-nav-list {
            width: 100%;
            .ant-tabs-tab {
              width: 50%;
              justify-content: center;
              padding: 14px;
              font-size: 18px;
              background: #123b5633;
              &:hover,
              div:hover {
                color: #ffffff;
              }
            }
            .ant-tabs-tab-active {
              background: #1b4f72;
              div {
                color: #ffffff;
              }
              &:hover,
              div:hover {
                color: #000000d9;
              }
            }
          }
        }
      }
    }
    .ant-modal-footer {
      padding: 0;
      border: 0;
    }
  }
}

.order-trail-snapshot-help-modal {
  .ant-modal-content {
    padding: 0 !important;
    border-radius: 5px;
    .ant-modal-body {
      border-radius: 5px;
    }
    .ant-modal-footer {
      padding: 0;
      border: 0;
    }
  }
}

@import '../../styles/mixins';
.manage-subs-manage-accounts-audit-trail .manage-subs-sub-repositories-audit-trail{
  .chart-title {
      color: var(--blue-heading-color);
    }
  
    .subs-report-table .ant-table-body {
      max-height: calc(100vh - 335px) !important;
    }
  
    .common-report-table .ant-table-thead>tr>th {
      padding: 8px 8px;
    }
}

@import '../../styles/mixins';

.order-trail-order-trail {
    min-height: 550px;
    padding-top: 16px;
    padding-left: 16px;

    .customizations-layout-sider {
        display: flex;
        justify-content: space-between;
    }

    .sub-layout-content {
        flex: 1;
    }

    .ant-layout {
        background-color: unset;
    }

    .ant-layout-sider {
        background: none !important;
    }
}
@import '../../styles/mixins';

.order-trail-help-window {
  margin: 14px;
  .help-header {
    font-weight: bold;
    color: #000000D9;
    font-size: 10px;
    margin-bottom: 20px;
  }
  .help-col1 {
    font-size: 9px;
    color: #008CD2;
  }
  .help-col2 {
    font-size: 9px;
    padding-right: 4px; 
  }
}

@import '../../styles/mixins';

.manage-subs-add-customer-operate-modal {
    .series-tips-content-items-1 {
    top: 25px;
    left:70px;
  }
  .modifiedByName{
    padding: 0px;
  }
}
.ant-popover{
  width:250px;
}

@import '../../styles/mixins';

.reports-report-table {
  .limit-error-alert {
    margin: 0 0 6px 0;
  }
  .report-table .ant-table-thead > tr > th.badge-item-col {
    font-size: 0;
  }
  .report-table .ant-table-tbody > tr > td.badge-item-col {
    padding: 0;
  }
  .order-badge-item, .tips-badge-item {
    padding: 16px 40px 16px 4px;
    .ant-badge-status-dot {
      width: 12px;
      height: 12px;
    }
  }
  .order-badge-item {
    padding: 0px;
    .ant-badge-status-text {
      margin-left: 4px;
    }
  }
  .ant-table-summary{
    background:#7edbff7a;
    font-weight: 700;
    .badge-item-col{
      padding: 0;
    }
  }
    // .ant-table-footer {
    //   padding: 0px;
    //   font-weight: 700;
    // }
    .ant-table-body{
      position: relative;
      z-index: 2;
    }
}
.report-export-button {
  margin-top: -60px;
  margin-bottom: 20px;
  .ant-btn:last-child {
    margin-left: 15px
  }
}
.report-export-button-modal {
  margin-top: -60px;
  margin-bottom: 20px;
  margin-right: 30px;
  .ant-btn:last-child {
    margin-left: 15px
  }
}

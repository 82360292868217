@import '../../styles/mixins';

.manage-subs-manage-outs-product-card {
  // height: 200px;
  width: 362px;
  padding-left: 10px;
  border: 0.5px solid #cbcbcb;
  font-size: 12px;
  border-radius: 3%;
  // color: #666666;
    
  .label-left{
    padding-left: 2px;
  }
  .item-no-of-subbed{
    color: #1c962a;
    padding-left: 25px;
  }
  .frozen-icon{
    margin-left: -50px;
    margin-top: 15px;
  }
  .product-card-static-form-item-value{
    font-size: 11px;
    color: #666666;
    label.product-card-custom-form-item-value {
      font-size: 11px;
      color: #666666;
    }
    .ant-form-item-control-input-content{
      padding-top: 10px;
    }
  }
  .product-card-value {
    font-size: 16px;
    font-weight: 700;
  }
  .ant-input{
    padding:0px 0px;
    height: 25px;
    font-size: 14px;
  }
  .ant-btn{
    padding: 0px 2px;
    height: 25px;
  }
  .ant-picker{
    height: 25px;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
    height: 25px;
    padding: 0px;
  }
  .ant-form-item-label > label{
    font-size: 12px;
    color: #666666;
  }
  .product-card-static-col {
    height: 18px;
    min-height: 18px;
    &.aosh{
      margin-top: -10px;
      }
    .product-card-static-form-item-value-1 {
      height: 18px;
      min-height: 18px;
      .ant-form-item-label > label{
        font-size: 12px;
        color: #666666;
        // align-items: flex-start;
        height: 18px;
      }
      &.aosh{
        font-size: 9px;
        .ant-form-item-label > label{
          font-size: 11px;
          color: #666666;
          // align-items: flex-start;
          height: 18px;
        }
      }
    }
    .item-quantity{
      color: #0071bc;
      font-size: 15px;
      height: 18px;
      min-height: 18px;
      div.ant-form-item-control-input {
        height: 18px;
        min-height: 18px;
      }
    }
    .item-price{
      color: #db930b;
      // padding-right: 40px;
      height: 18px;
      min-height: 18px;
      div.ant-form-item-control-input {
        height: 18px;
        min-height: 18px;
      }
    }
  }
  .series-tips-content-items-2 {
    white-space: nowrap;
    margin-top:-10px ;
    .ant-form-item-control-input {
      // min-height: unset;
      line-height: 1;
    }
    .tips-content-item {
      position: static;
    }
  }
  div.kosher-flag {
    height: 32px;
    color: #666666;
    padding-top: 3px;
    // line-height: 32px;
    span {
      font-size: 14px;
      border: 1px solid #666666;
      height: 22px;
      width: 22px;
      border-radius: 50%;
      display: inline-block;
      text-align: center;
      margin-left: 6px;
    }
  }
}

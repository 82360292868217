@import '../../styles/mixins';

.order-trail-snapshot-order-header {
  border: 1px solid #1b4f72;
  height: 400px;
  .header-row {
    border: 1px solid #e8e8e8;
  }
  .header-item {
    padding: 5px;
    flex: 1;
    .header-value {
      color: rgba(0, 0, 0, 0.65);
    }
  }
}

@import '../../styles/mixins';

.manage-subs-maintain-accounts {
  .chart-title {
    color: var(--blue-heading-color);
  }
  .yes-tag {
    padding: 2px 14px;
    background: #e3f4de;
    color: #6daf55;
    border: 0;
  }
  .no-tag {
    padding: 2px 14px;
    background: #f0d8d8;
    color: #e83223;
    border: 0;
  }
  .actions-cell .ant-btn {
    color: #1A1A1A;
  }
  .ant-table-tbody > tr > td {
    font-weight: 700;
  }
  .subs-report-table .ant-table-body {
    max-height: calc(100vh - 335px) !important;
  }
}

.manage-subs-manage-outs-operate-modal{
    .ant-modal-content {
        padding: 22px 27px;
        border-radius: 16px;
        .ant-modal-header {
          padding: 0;
          .ant-modal-title {
            color: #194F72;
            font-size: 20px;
            text-transform: uppercase; 
            font-weight: 400;
          }
        }
        .ant-modal-body {
          border: 0;
          box-shadow: unset;
          padding-top: 20px;
          padding-bottom: 20px;
          .subs-upload-btn {
            width: 400px;
            color: #666666;
            margin: 6px 0;
            padding: 16px 24px;
            box-sizing: content-box;
            font-size: 24px;
            text-align: left;
            .anticon {
              font-size: 32px;
              padding-right: 24px; 
            }
            a {
              color: inherit;
            }
          }
        }
      }
      .toggle-desc {
        line-height: 32px;
      }
      .sub-item-list-header {
        margin-top: 20px;
        background: #E3F3FC;
        border: 1px solid #0071BC;
        .ant-col {
          line-height: 28px;
        }
      }
      
      
      .sub-item-list-item {
        padding: 6px 0px;
        border: 1px solid #0071BC;
        border-top: 0;
        .ant-col .ant-form-item {
          margin: 0;
        }
    }
    .ant-modal-footer {
        padding: 0;
        border: 0;
      }
    .ant-descriptions-bordered .ant-descriptions-item-label {
        background-color:transparent;
    }
    .ant-descriptions-bordered .ant-descriptions-view {
        border: none;
    }
    .ant-descriptions-bordered .ant-descriptions-item-label, .ant-descriptions-bordered .ant-descriptions-item-content {
         border-right: none; 
    }
    .ant-descriptions-bordered .ant-descriptions-row {
        border-bottom: none;
    }
    .ant-descriptions-item-label{
        width:130px;
        color: #194F72;
        font-weight: 700;
        &.not-available{
          color:#8e8e8e
        }
    }
    .ant-descriptions-item-content{
      
      font-weight: 600;
  }
    
    .outs-order-detail-table{
        .ant-table-thead > tr > th {
          // background-color: #E3F3FC;
          color: #194F72;
          font-weight: 700;
          padding: 2px 2px;
          border-bottom: 1px solid #84b6d8;
          border-right: 0px;
          border-collapse: collapse; 
          .ant-table-column-sorter {
            color: #194F72;
          }
        }
        .ant-table-tbody > tr > td {
          border-bottom: 0px; 
        }
        .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table {
          margin: -4px 10px -4px 41px;
        }
    }
    .outs-inventory{
      .col-static-value{
        padding-top: 10px;
      }
      .col-desc {
        font-size: 10px;
        color: #666666;
      }
    }  
}
.manage-subs-manage-outs-operate-modal .outs-order-detail-table .ant-table-thead > tr > th {
  background-color: #ffffff;
}
// .add-sub{
//   .ant-form{
//     // font-weight: 700;
//   }
//   .ant-modal-header .ant-modal-title {
//     display: none;
//   }
//   .ant-modal-content .ant-modal-body{
//     padding: 0px 15px;
//   }
//   .ant-form-item{
//     margin-bottom: 0px;
//   }
//   .series-tips{
//     margin-left: 20px;
//     margin-top: -10px;
//   }
//   .series-tips-content-items, .series-tips-content-items-1, .series-tips-timestamp-items {
//     white-space: nowrap;
//     .ant-form-item-control-input {
//       min-height: unset;
//       line-height: 1;
//     }
//     .tips-content-item {
//       position: static;
//     }
//   }
//   .static-form-item-value label.custom-form-item-value {
//     color: #666666;
//     font-size: 17px;
//     font-weight: 700;
//   }
//   label.custom-form-item-value {
//     // font-size: 14px;
//     // font-weight: 700;
//   }
//   .ant-carousel .slick-slider {
//     position: static;
//   }
//     .ant-carousel .slick-prev,
//   .ant-carousel .slick-prev:hover,
//   .ant-carousel .slick-prev:focus {
//     font-size: inherit;
//     left: 10px;
//     z-index: 2;
//     color: blueviolet;
//   }

//   .ant-carousel .slick-next,
//   .ant-carousel .slick-next:hover,
//   .ant-carousel .slick-next:focus {
//     font-size: inherit;
//     right: 10px;
//     z-index: 2;
//     color: blueviolet;
//   }
//   .product-card{
//     display: inline-block;
//     padding-left: 5px;
//   }
//   .recommend-prodcuts-title{
//     color: #194F72;
//     font-size: 14px;
//     font-weight: 700;
//     margin-bottom: 10px;
//   }
// }
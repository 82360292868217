@import '../../styles/mixins';

  .ant-modal-content {
    padding: 15px 10px;
  }
  .ant-modal-header {
    border: 0;
    .ant-modal-title {
      font-size: 18px;
      font-weight: 600;
      display: flex;
      align-items: center;
    }
  }
  .ant-modal-body {
    box-sizing: border-box;
    border: 1px solid #979797;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
    padding: 20px 10px;
  }
  .report-table {
    .ant-table-thead > tr > th {
      color: #194F72;
      background-color: #E3F3FC;
      box-shadow: 0 1px 0 0 rgb(0 0 0 / 85%);
      padding: 6px 16px;
      font-weight: 700;
      .ant-table-column-sorter {
        color: #194F72;
      }
    }
    .ant-table-tbody > tr > td {
      padding: 6px 16px;
    }
    .report-link {
      color: var(--blue-primary-color);
      text-decoration: underline;
    }
    .report-status-normal {
      color: var(--green-color);
      text-transform: uppercase;
    }
    .report-status-error {
      color: var(--red-color);
      text-transform: uppercase;
    }
    .report-status-warning {
      color: var(--orange-color);
      text-transform: uppercase;
    }
    .report-pagination {
      padding-top: 16px;
    } 
    .ant-btn-link-disabled{
      cursor:none;
      pointer-events:none;
    }   
  }
  .report-footer {
    margin: 16px 0;
    .ant-btn:last-child {
      margin-left: 15px
    }
  }
  .dashboard-order-detail-report-modal{
    .ant-modal-body{
      border: unset;
      box-shadow: unset;
      .reports-new-export-button .ant-btn{
        height: 25px;
        border-radius: 34px;
        line-height: 48px;
        padding: 0px 8px;
        span:last-child{
           margin-left: 5px;
      }
      }
    }
  }
  .dashboard-order-report-table{
    .ant-table-body{
      border-left: unset;
      border-bottom: none;
    }
  .ant-table-tbody>tr>td,
    .ant-table-thead>tr>th {
      border-bottom: 0.54px solid #E4E5E6;
     
    }
    .ant-table-header {
      border-radius: unset;
      border-right: none;
      border-left: none;
      border-top: 0.54px solid #E4E5E6;
    }
    .ant-table-thead>tr>th {
      padding: 5px 16px;
      font-weight: 400;
    }
  
    .ant-table-tbody>tr>td {
      padding: 8px 16px;
    }
    .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
      position: absolute;
      top: 50%;
      right: 0;
      width: 0px;
      height: 0em;
      background-color: rgba(0, 0, 0, 0.06);
      transform: translateY(-50%);
      transition: background-color 0.3s;
      content: '';
    }
    
  }
  .report-link {
    color: var(--blue-primary-color);
    text-decoration: underline;
  }
  .report-status-normal {
    color: var(--green-color);
    text-transform: uppercase;
  }
  .report-status-error {
    color: var(--red-color);
    text-transform: uppercase;
  }
  .report-status-warning {
    color: var(--orange-color);
    text-transform: uppercase;
  }
  .report-pagination {
    padding-top: 16px;
  }  
  .ant-btn-link-disabled{
    cursor:none;
    pointer-events:none;
  }   
  @media screen and (max-width: 800px) {
    .sia-mobile-editandDelete{
      position: absolute;
      right: 0;
      top:-5px;
    }
    .ant-pagination-pre{
      min-width: 23px !important;
    }
    .ant-modal-header{
      padding:0;
      border-bottom:1px solid #E4E5E6;
      padding-bottom:20px;
      padding-left:20px;
    }
    .ant-modal-close-x{
      width: 36px;
      height: 36px;
      line-height: 36px;
    }
    .ant-modal-content{
      padding:8px 12px;
    }
    .ant-modal-body{
      padding-top:10px !important;
    }
    .sia-mobile-box{
      position: relative;
    }
    .sia-mobile-checkbox{
      position: absolute;
      top:9px;
    }
    .sia-mobile-checkbox_all{
      position: absolute;
      top:13px;
      left:13px;
    }
    .ant-pagination-item{
      min-width: 28px;
      height: 28px;
      margin-right: 4px;
      line-height: 27px;
    }
    .reports-mobile-list{
      ul li{
        justify-content: start !important;
      }
    }
  }
@media screen and (max-width: 800px) {

  .dashboard-order-detail-report-modal {
    .ant-modal-body {
      .reports-new-export-button .ant-btn {
        padding: 0px 3px;
      }

      .report-export-button-modal {
        margin-top: -50px;
      }

      .reports-mobile-list {
        margin-top: 25px;
      }
    }
  }
}


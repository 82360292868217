@import '../../styles/mixins';
.order-spec-modal {
  top: 10px;
  .ant-modal-body {
    padding: 4px 10px;
  }
}
.reports-details-report-modal {
  .ant-modal-body{
    border: unset;
    box-shadow: unset;
  }
  .ant-modal-title {
    display: block;
  }
  .limit-error-alert {
    margin: 4px 0 0 0;
  }
  .order-steps {
    margin-bottom: 16px;
    padding: 50px 0px 12px 0px;
    border: 1px #e7e7e7 solid;
    .ant-steps-item-tail {
      padding: 3.5px 12px;
    }
    .ant-steps-item-tail::after {
      background-color: #9e9e9e;
      height: 2px;
    }
    .finish-step-item .ant-steps-item-icon > .ant-steps-icon {
      color: var(--green-color);
      font-size: 18px;
    }
    .ant-steps-item-icon {
      margin-left: 48px;
    }
    .wait-step-item, .cancel-step-item {
      .ant-steps-item-tail::after {
        background-color: #e7e7e7;
      }
      .ant-steps-item-icon {
        width: 18px;
        height: 18px;
        margin: 8px 0 8px 48px;
      }
      .ant-steps-item-icon > .ant-steps-icon {
        font-size: 0;
      }
    }
    .cancel-step-item .ant-steps-item-icon {
      border-color: var(--red-color);
      border-width: 4px;
    }
    .ant-steps-item:nth-child(odd) .ant-steps-item-container .ant-steps-item-content {
      position: relative;
      top: -78px;
    }
    .ant-steps-item-title {
      font-size: 12px;
      line-height: 18px;
    }
    .ant-steps-item-description {
      font-size: 12px;
    }
  }
  .relative-footer {
    position: relative;
    width: 160px;
    top: -64px;
    left: 700px;
  }
  .ant-table-summary{
    background:#7edbff7a;
    font-weight: 700;
    .badge-item-col{
      padding: 0;
    }
  }
}


@import '../../styles/mixins';

.home-app {
  background: #ffffff;
  // transform: scale(2.0);
}
.none{
  display: none !important;
}
.ant-menu-item-selected{
  color: #1B4F72 !important;
}
.ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after{
  border-right: 3px solid #1B4F72 !important;
}
.home-custom-menu li.ant-menu-item.ant-menu-item-selected .ant-menu-title-content a{
  color: #1B4F72 !important;
}
.home-custom-menu .menu-list,.home-custom-menu .menu-list .menu-item a{
  color: #A1A1A1 !important;
}
.home-custom-menu .menu-list .menu-item a:hover,.ant-menu-item a:hover{
  color: #1B4F72 !important;
}
ul,li{
  list-style: none;
}
@media screen and (min-width: 320px) {
  html {font-size: 8.5333px !important; }
}

@media screen and (min-width: 360px) {
  html {font-size: 9.6px !important; }
}

@media screen and (min-width: 375px) {
  html {font-size: 10px !important; }
}

@media screen and (min-width: 384px) {
  html {font-size: 10.24px !important; }
}

@media screen and (min-width: 411px) {
  html {font-size: 10.96px !important; }
}

@media screen and (min-width: 414px) {
  html {font-size: 11.04px !important; }
}

@media screen and (min-width: 448px) {
  html {font-size: 11.9467px !important; }
}

@media screen and (min-width: 512px) {
  html {font-size: 13.6533px !important; }
}

@media screen and (min-width: 544px) {
  html {font-size: 14.5067px !important; }
}

@media screen and (min-width: 576px) {
  html {font-size: 15.36px !important; }
}

@media screen and (min-width: 608px) {
  html {font-size: 16.2133px !important; }
}

@media screen and (min-width: 640px) {
  html {font-size: 17.0667px !important; }
}

@media screen and (min-width: 750px) {
  html {font-size: 20px !important; }
}

@media screen and (min-width: 768px) {
  html {font-size: 20.48px !important; }
}

@media screen and (min-width: 800px) {
  html {font-size: 21.3333px !important; }
}

@media screen and (min-width: 1024px) {
  html {font-size: 27.3067px !important; }
}

/*Preset Animation*/
/*Fade in animation*/
.fadeIn{
  animation-duration: 0.3s;
  -webkit-animation-name:fadeIn;
  animation-name:fadeIn
}

@-webkit-keyframes fadeIn{
  0%{opacity:0}
  100%{opacity:1}
}

@keyframes fadeIn{
  0%{opacity:0}
  100%{opacity:1}
}

/*Fade out animation*/
.fadeOut{
  animation-duration: 0.3s;
  -webkit-animation-name:fadeOut;
  animation-name:fadeOut
}


@-webkit-keyframes fadeOut{
  0%{opacity:1}
  100%{opacity:0}
}

@keyframes fadeOut{
  0%{opacity:1}
  100%{opacity:0}
}

/*The side slide menu slides out of the animation*/
.slideInLeft{
  animation-duration: 0.4s;
  -webkit-animation-name:slideInLeft;
  animation-name:slideInLeft
}
@keyframes slideInLeft {
  0% {
      opacity:1;
      -webkit-transform:translateX(-110px);
      -ms-transform:translateX(-110px);
      transform:translateX(-110px)
  }
  100% {
      -webkit-transform:translateX(0);
      -ms-transform:translateX(0);
      transform:translateX(0)
  }
}

@-webkit-keyframes slideInLeft {
  0% {
      opacity:1;
      -webkit-transform:translateX(-110px);
      transform:translateX(-110px)
  }
  100% {
      -webkit-transform:translateX(0);
      transform:translateX(0)
  }
}

/*Slide menu to expand the animation*/
.slideOutLeft{
  animation-duration: 0.4s;
  -webkit-animation-name:slideOutLeft;
  animation-name:slideOutLeft
}
@keyframes slideOutLeft {
  0%{
      opacity:1;
      -webkit-transform:translateX(0);
      -ms-transform:translateX(0);
      transform:translateX(0)
  }
  100%{
      opacity:1;
      -webkit-transform:translateX(-310px);
      -ms-transform:translateX(-310px);
      transform:translateX(-310px)
  }
}

@-webkit-keyframes slideOutLeft {
  0%{
      opacity:1;
      -webkit-transform:translateX(0);
      transform:translateX(0)
  }
  100%{
      opacity:1;
      -webkit-transform:translateX(-310px);
      transform:translateX(-310px)
  }
}

/**
Downward floating animation
*/
.fadeInDownRe{
  -webkit-animation-name:fadeInDownRe;
  animation-name:fadeInDownRe;
  animation-duration: 0.4s;
}



@-webkit-keyframes fadeInDownRe {
  0% {
      opacity:0;
      -webkit-transform:translateY(-20px);
      transform:translateY(-20px);
  }
  100% {
      opacity:1;
      -webkit-transform:translateY(0);
      transform:translateY(0)
  }
}

@keyframes fadeInDownRe {
  0% {
      opacity:0;
      -webkit-transform:translateY(-20px);
      -ms-transform:translateY(-20px);
      transform:translateY(-20px)
  }
  100% {
      opacity:1;
      -webkit-transform:translateY(0);
      -ms-transform:translateY(0);
      transform:translateY(0)
  }
}

/**
Downward floating animation (slow)
*/
.fadeInDownSlow{
  -webkit-animation-name:fadeInDownSlow;
  animation-name:fadeInDownSlow;
  animation-duration: 0.8s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}
@-webkit-keyframes fadeInDownSlow {
  0% {
      opacity:0.6;
      -webkit-transform:translateY(-32px);
      transform:translateY(-32px);
  }
  100% {
      opacity:1;
      -webkit-transform:translateY(0);
      transform:translateY(0);
  }
}

@keyframes fadeInDownSlow {
  0% {
      opacity:0.6;
      -webkit-transform:translateY(-32px);
      -ms-transform:translateY(-32px);
      transform:translateY(-32px)
  }
  100% {
      opacity:1;
      -webkit-transform:translateY(0);
      -ms-transform:translateY(0);
      transform:translateY(0);
  }
}

/**
Zoom down and float animation
*/
@keyframes fadeInScaleDown{
  from {
      opacity: 0;
      -webkit-transform: scale(.9) translate3d(0, -12px, 0);
      -ms-transform: scale(.9) translate3d(0, -12px, 0);
      transform: scale(.9) translate3d(0, -12px, 0);
  }
  to {
      opacity: 1;
      -webkit-transform: scale(1) translate3d(0, 0, 0);
      -ms-transform: scale(1) translate3d(0, 0, 0);
      transform: scale(1) translate3d(0, 0, 0);
  }
}

.fadeInScaleDown{
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-name: fadeInScaleDown;
  animation-name: fadeInScaleDown;
  -webkit-animation-fill-mode: backwards;
  animation-fill-mode: backwards;
  animation-timing-function: ease-in-out;
  transition: max-height linear;
}
@import '../../styles/mixins';

.order-trail-order-search-filters {
  // background-color: #f5f5f5;
  // padding: 8px 12px 0 12px;
  // box-shadow: 2px 0 6px 2px rgba(0,0,0,0.2);
  // min-height: calc(100vh - 56px);
  // height: 100%;
  padding: 8px 12px 0 12px;
  background: #FFFFFF;
  box-shadow: 1px 1px 4px rgba(106, 115, 123, 0.15);
  border-radius: 8px;
  min-height: calc(100vh - 56px);
  height: 100%;
  .sub-title {
    // padding-bottom: 12px;
    padding-bottom: 8px;
  }
  .filter-form {
    .ant-row.ant-form-item {
      margin-bottom: 8px;
      .ant-col {
        &.ant-form-item-label {
          padding: 0 0 2px;
        }
        .ant-picker {
          width: 100%;
        }
      }
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border: 1px solid #dcdfe6;
      color: #606266;
      background-color: #FFFFFF;
      border-radius: 4px;
    }
    .ant-form-item-has-error .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border-color: #ff4d4f;
    }
    .ant-select-selection-placeholder{
      color: #606266 !important;
    }
    .ant-picker{
      border: 1px solid #dcdfe6 ;
      background-color:#FFFFFF;
      border-radius: 4px;
      height: 32px;
      width:100%;
    }
    // .ant-form-item{
    //   padding: 2px 5px;
    // }
    .ant-input {
      border: 1px solid #dcdfe6;
      color: #606266;
      background-color: #FFFFFF;
      border-radius: 4px;
    }
    .filter-btn, .filter-btn:hover{
      color: #008CD2 !important;
      border : 1px solid #008CD2 !important;
      border-radius: 30px !important;
    }
    .filter-primary-btn{
      border-radius:30px !important;
    }
    .ant-picker-status-error {
      border: 1px solid #ff4d4f;
      border-radius: 4px;
      box-shadow: unset;
    }
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }
  input {
    -moz-appearance: none;
  }
}

@import '../../styles/mixins';

.dashboard-territory-filters {
  padding: 8px 12px 0 12px;
  background: #FFFFFF;
  box-shadow: 1px 1px 4px rgba(106, 115, 123, 0.15);
  border-radius: 8px;
  min-height: calc(100vh - 56px);
  height: 100%;
  .sub-title {
    padding-bottom: 8px;
  }
  .filter-form {
    .ant-row.ant-form-item {
      margin-bottom: 8px;
      .ant-col {
        &.ant-form-item-label {
          padding: 0 0 2px;
        }
        .ant-picker {
          width: 100%;
        }
      }
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border: 1px solid #dcdfe6;
      color: #606266;
      background-color: #FFFFFF;
      border-radius: 4px;
    }
    .ant-form-item-has-error .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border-color: #ff4d4f;
    }
    .ant-select-selection-placeholder{
      color: #606266 !important;
    }
    .ant-picker{
      border: 1px solid #dcdfe6 ;
      background-color:#FFFFFF;
      border-radius: 4px;
      height: 32px;
      width:100%;
    }
    .ant-form-item{
      padding: 10px 5px;
    }
    .filter-btn, .filter-btn:hover{
      color: #008CD2 !important;
      border : 1px solid #008CD2 !important;
      border-radius: 30px !important;
    }
    .filter-primary-btn{
      border-radius:30px !important;
    }
    .ant-picker-status-error {
      border: 1px solid #ff4d4f;
      border-radius: 4px;
      box-shadow: unset;
    }
  }
}
.dashboard-filter-head{
  display: none;
}
.ant-input-password-icon{
  display: none;
}
@media screen and (max-width: 800px) {
  .reports-new-header{
    padding-top:10px;
  }
  .dashboard-territory-filters{
    min-height: 50px;
    height: 50px;
    margin-bottom:15px;
    padding: 16px 12px 0 12px;
    .none{
      display: none !important;
    }
    .dashboard-filter-item{
      position: fixed;
      z-index: 99999;
      width:92%;
      height: auto;
      padding-bottom:10px;
      left: 16px;
      top: 80px;

      background: #FFFFFF;
      box-shadow: 1px 1px 4px rgba(106, 115, 123, 0.15);
      border-radius: 8px;
    }
    .ant-form-item{
      margin-bottom:0px;
      width:80%;
      margin-left:10%;
    }
    .dashboard-filter-head{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left:10px;
      padding-right: 10px;
      height: 40px;
      h4{
        color: #1B1B1B;
        font-size: 18px;
        font-weight: 600;
        margin-top:14px;
        margin-left:6px;
      }
    }
  }
}
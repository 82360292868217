@import '../../styles/mixins';

.reports-new-header {
    padding-left:5px;
    // padding-bottom: 16px;
    .title {
        padding-left:5px;
        font-weight: 600;
    }

    .date {
       padding-left: 6px;
       padding-top: 8px;
       color: #979797;
    }
    .ant-btn{
        height: 20px;
        padding: 0px;
    }
}
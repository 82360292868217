@import '../../styles/mixins';

.manage-subs-manage-subs-navigation {
  .menu-list {
    background-color: #f7f7f9;
    li.menu-item {
      margin: 10px 0px;
    }
    li.ant-menu-item-selected {
      background-color: transparent;
    }
    li.ant-menu-item::after {
      border: 0;
    }
  }
  .menu-word-break{
    white-space: break-spaces;
    /* height: 50px; */
    line-height: 20px;
  }
}

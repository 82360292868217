@import '../../styles/mixins';

.order-trail-order-trail-orders {
  margin-top: 2px;
  padding: 0 8px 12px 8px;
  box-shadow: unset;
  border-radius: 3px;
  background-color: #FFFFFF;
  .chart-title {
    padding: 8px 0;
  }
  .ant-table table {
    border-collapse: collapse;
    border-spacing: 0;
  }

}

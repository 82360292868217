@import '../../styles/mixins';

.handle-overflow{
  overflow: hidden;
  white-space: nowrap;
}

.manage-subs-manage-substitutions {
  .chart-title {
    color: var(--blue-heading-color);
  }
  .sub-badge-item {
    margin-left: 5px; 
    .ant-badge-status-dot {
      width: 10px;
      height: 10px;
    }
  }
  .blank-badge {
    margin-left: 23px;
  }
  .subs-header-btn {
    color: #285579;
    border-color: #285579;
    margin: 4px 2px;
  }
  .parent-actions .ant-btn {
    color: #1A1A1A;
  }
  .report-pagination {
    margin-bottom: 4px !important;
  }
  .subs-report-table .ant-table-body {
    max-height: calc(100vh - 335px) !important;
  }
  .detail-info {
    margin-left: 22px;
  }
}

.subs-report-table {  
  .ant-table-tbody > tr > td {
    padding: 0px 8px;
    font-size:12px;
    border-bottom: 1px solid #d9d9da;
    // border-right: 1px solid #0071BC;
  }
  .ant-table-tbody > tr:nth-child(2) > td {
    // border-top: 1px solid #0071BC;
  }
  .ant-table-tbody > tr > td:first-child {
    // border-left: 1px solid #0071BC;
  }
  .expanded-report-table {
    .ant-table-thead > tr > th {
      //padding: 4px 8px;
      font-weight: 500;
      font-family: MyriadPro, sans-serif;

    }
    .ant-table-tbody > tr > td {
      padding: 4px 8px;
      font-size: 12px;
      border: 0;
      border-bottom: 1px solid #f0f0f0;
    }
  }
}

.col-title {
  font-weight: 700;
  font-size:12px;
}
.col-desc {
  font-family: MyriadPro, sans-serif;
  font-size: 12px;
  color: #949494;
  font-weight: 10;
  margin-top: -2px;
}